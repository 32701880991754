import React from 'react'
import styled from 'styled-components';

const ContentBlock = styled.div`
  h2 {
    text-align: ${props => props.alignment ? 'center' : 'left'};
  }
  p {
    text-align: ${props => props.alignment ? 'center' : 'left'};
  }
  
`;
const Content = props => (
  <ContentBlock alignment={props.aligncenter}>
    {props.heading &&
      <h2>{props.heading}</h2>
    }
    {props.text &&
      <p>{props.text}</p>
    }
  </ContentBlock>
)

export default Content

