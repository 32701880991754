import React from 'react';
import { StaticQuery, graphql } from "gatsby"
import styled from 'styled-components';
import MobileFooter from './lp_mobilefooter'
import DesktopFooter from './lp_desktopfooter'

const FooterWrapper = styled.div`
background-color: #fff;
border-top: 1px solid rgb(246, 246, 246);
`;
const FooterContainer = styled.div`
    display: none;

    @media (min-width:768px) {
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
    }
`;

export default (props) => (
  <StaticQuery
    query={graphql`
      query LP_FooterQuery {
        file(relativePath: { eq: "tuvlogo.jpg" }) {
          childImageSharp {
            fixed(width: 106, height: 106, quality: 80) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    `}
    render={data => (
      <FooterWrapper>
      <MobileFooter offerconditions={props.offerconditions} langlinkde={props.langlinkde} langlinken={props.langlinken} currentlang={props.currentlang} tuvlogo={data.file.childImageSharp.fixed}/>
      <FooterContainer>
        <DesktopFooter offerconditions={props.offerconditions} tuvlogo={data.file.childImageSharp.fixed} currentlang={props.currentlang}/>
      </FooterContainer>
    </FooterWrapper>
    )}
  />
)

