import React from 'react'
import styled from 'styled-components'


const OfferSection = styled.section`
background-color: rgb(100, 59, 162);

`;

const OfferDiv = styled.div`
display: inherit;

width: 100%;
max-width: 1312px;
margin: 0 auto;
    display: flex;
    justify-content: center;
    padding: 32px 0px;
`;

const OfferContainer= styled.div`
    box-sizing: border-box;
    flex: 0 0 auto;
    flex-basis: auto;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    justify-content: center;

    @media only screen and (min-width:0em) {
        flex-basis: 100%;
        max-width: 100%;
        display: block;
    }

    @media only screen and (min-width:62em) {
        flex-basis: 75%;
        max-width: 75%;
        display: flex;
        justify-content: center;
      }

      @media (min-width:768px) {
        padding-top: 40px;
        padding-bottom: 40px;
      }
`;
const OfferHeader = styled.h2`
    text-align: center;
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    @media (min-width:576px) {
        font-size: 32px;
    }
`;



const Offer = ({ offer }) => (
    <OfferSection>
        <OfferDiv>
            <OfferContainer>
                <OfferHeader>{offer}</OfferHeader>
            </OfferContainer>
        </OfferDiv>
    </OfferSection>
  )
  
  
  
export default Offer