import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image/withIEPolyfill'
import { Link } from 'gatsby'

const CardCol = styled.div`
  box-sizing: border-box;
  flex: 0 0 auto;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  margin: 0 auto;
  margin-bottom: 32px;
  
        
  @media only screen and (min-width:0em) {
    flex-basis: 100%;
    max-width: 100%;
    display: block;
  }
  
  @media only screen and (min-width:36em) {
    flex-basis: 50%;
    max-width: 50%;
    display: block;
  }
  
  @media only screen and (min-width:62em) {
    flex-basis: 33.333333333333336%;
    max-width: 33.333333333333336%;
    display: block;
  }
  
  @media (min-width:992px) {
    margin: 0;
    padding: 16px;
  }
`;

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  position: relative;
  height: 100%;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
 
  a {
    transition: 500ms cubic-bezier(0.165, 0.84, 0.44, 1);
    background-image: none;
  }
`;

const CardContent = styled.div`
  padding: 24px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;

  *:last-child {
    margin-bottom: 0;
    text-align: left;
  }

  p {
      color: #2D2F3D;
      font-size: 18px;
      font-weight: 300;
      line-height: 1.6;
      font-weight: 500;
      padding-top: 0px !important;
      margin-bottom: 12px;
      @media (min-width:576px) {
          font-size: 21px;
      }
  }
  ul {
    min-height: 250px;
  }
  
`;

const CardImage = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;
  padding-bottom: 60%;
  height: 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  .gatsby-image-wrapper {
    position: static !important;
  }

`;

const CardHeader = styled.span`
  font-size: 21px;
  line-height: 1.6;
  margin: 0px;
  font-weight: 500;
  margin-bottom: 12px;
`;
const Highlight = styled.span`
display: inline;
padding: 4px;
line-height: 1.9;
color: rgb(255, 255, 255);
background: rgb(0, 79, 239) none repeat scroll 0% 0%;
box-shadow: rgb(0, 79, 239) 8px 0px 0px, rgb(0, 79, 239) -8px 0px 0px;
position: relative;
left: 8px;
`;

const CardSubHeader= styled.p`
  font-weight: 500;
  font-size: 18px !important;
  margin-bottom: 24px;
`;

const CardListItem = styled.li`
    position: relative;
    padding: 4px 0px;
    break-inside: avoid;
    width: 100%;
    padding-left: 28px;
    color:
    rgb(45, 47, 61);
    font-size: 18px;
    font-weight: 300;
    line-height: 1.6;
    margin: 0px 0px 8px;
    padding-top: 4px;
    
  :before {
    content: "●";
    font-family: "Arial, Helvetica, sans-serif";
    display: inline-block;
    position: absolute;
    left: 4px;
    top: 10px;
    font-size: 12px;
    color:
    rgb(0, 79, 239);
  }
`;

const CardLinkContainer = styled.div`
  margin-top: 34px;
  padding-bottom: 10px;
`;

const CardLink = styled(Link)`
  min-width: 118px;
  background-color: rgb(0, 79, 239);
  border-radius: 3px;
  color: #FFFFFF;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  padding: 12px 24px;
  text-decoration: none;
  transition: all 200ms cubic-bezier(0.165, 0.84, 0.44, 1);
  width: auto;
  color: #FFFFFF;
  position: relative;
  text-transform: none;

  :hover,
  :active,
  :focus {
    background-color: rgb(25, 96, 240);
    border: none;
    color: #FFFFFF;
  }

`;

const CardText = styled.div`
margin-top: 12px;
  p {
    font-size: 18px;
      color: rgb(45, 47, 61);
      font-weight: 300;
      line-height: 1.6;
  }
`;

const LandingCardText = props => (

<CardCol>
    <CardBody>
      <CardImage>
      <Img fixed={props.cardimg} 
          alt={props.cardcontent}
          objectFit="cover"
      />
      </CardImage>
      <CardContent>
        <p>
            <CardHeader>{props.heading}</CardHeader>
        </p>
        <CardText>
          <p>
            <span>{props.cardcontent}</span>
          </p>
        </CardText>
      </CardContent>
    </CardBody>
</CardCol>

)
export default LandingCardText