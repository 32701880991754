import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/landingpages/lp_layout'
import Seo from '../components/seo/mainseo'
import PageBannerOG from '../components/pagebanner/pagebanner'
import MainNav from '../components/navigation/mainnav'
import PageBannerMessage from '../components/pagebanner/pagebannermessage'
import Container from '../components/container'
import LandingCard from '../components/cards/lpcard'
import LandingCardText from '../components/cards/lpcardtext'
import CardDeck from '../components/cards/carddeck'
import FeatureCardList from '../components/cards/featurecardlist'
import PageContact from '../components/forms/pagecontact-sf'
import Offer from '../components/offer'
import styled from 'styled-components'
import BerlitzAdvantages from '../components/berlitzadvantages'
import ContentContainer from '../components/contentcontainer'
import ContentBlock from '../components/contentblock'
import CenteringDiv from '../components/centeringdiv'
import PageBlock from '../components/pageblock'


const SectionMain = styled.section`
    padding-top: 32px;
    background-color: rgb(252, 252, 252);
    @media (min-width:768px) {

    }
`;


const LandingPage = ({ data }) => {
  const { markdownRemark: post } = data
  var delink = "de";
  var enlink = "en";
  const metaUrlalt="https://www.berlitz-augsburg.de/"
  const metaUrl="https://www.berlitz-augsburg.de"

  if (post.frontmatter.language === "de"){
    delink = `${metaUrl}${post.fields.slug}`
    enlink = `${metaUrlalt}${post.frontmatter.seo.altlanguagelink}`;
  } else if (post.frontmatter.language === "en") {
    delink = `${metaUrlalt}${post.frontmatter.seo.altlanguagelink}`;
    enlink = `${metaUrl}${post.fields.slug}`
  }
  return (
    <Layout offerconditions={post.frontmatter.offerconditions} currentlang={post.frontmatter.language} langlinkde={delink} langlinken={enlink}>
      <Seo language={post.frontmatter.language}
        title={post.frontmatter.seo.metatitle}
        description={post.frontmatter.seo.metadescription}
        image={data.imagesocial.frontmatter.featuredimage.childImageSharp.fixed}
      />
      {post.frontmatter.language === "de" &&
        <PageBannerOG title={post.frontmatter.title} alt={post.frontmatter.displaytitle} isLanding={post.frontmatter.islandingpage}>
          <MainNav langlinkde={delink} langlinken={enlink} currentlang={post.frontmatter.language} landingpage={post.frontmatter.landingpage}/>
          <PageBannerMessage bannerbutton={post.frontmatter.bannerbutton} landingpage={post.frontmatter.landingpage} supertitle={post.frontmatter.supertitle} pageTitle={post.frontmatter.displaytitle} pageSubtitle={post.frontmatter.pagesubtitle} isHome={post.frontmatter.ishomepage} currentlang={post.frontmatter.language}/>
        </PageBannerOG>
      }
      {post.frontmatter.language === "en" &&
        <PageBannerOG title={post.frontmatter.imagetitle} alt={post.frontmatter.displaytitle} isLanding={post.frontmatter.islandingpage}>
          <MainNav langlinkde={delink} langlinken={enlink} currentlang={post.frontmatter.language} landingpage={post.frontmatter.landingpage}/>
          <PageBannerMessage bannerbutton={post.frontmatter.bannerbutton} landingpage={post.frontmatter.landingpage} supertitle={post.frontmatter.supertitle} pageTitle={post.frontmatter.displaytitle} pageSubtitle={post.frontmatter.pagesubtitle} isHome={post.frontmatter.ishomepage} currentlang={post.frontmatter.language}/>
        </PageBannerOG>
      }
      {post.frontmatter.leadingcallout  &&
        <Offer offer={post.frontmatter.leadingcallout}/>
      }
      <SectionMain role="main" id="intro">
      {post.frontmatter.pagecontentblocks  &&
          <ContentBlock background="true">
            <Container>
              <CenteringDiv>
                <ContentContainer>
                  {post.frontmatter.pagecontentblocks.map(item => (
                    <PageBlock         
                      heading={item.heading}
                      text={item.text}
                      aligncenter={false}
                    />
                  ))}
                </ContentContainer>
              </CenteringDiv>
            </Container>
          </ContentBlock>
        }
        {post.frontmatter.advantages &&
          <BerlitzAdvantages advantagesheader={post.frontmatter.advantagesheader}
                             advantages={post.frontmatter.advantages}
                             currentlang={post.frontmatter.language} 
          />
        }
        {post.frontmatter.textcards  &&
          <ContentBlock background={false} landingpage="true" aligncenter={true}>
                      <h2>{post.frontmatter.cardsheader}</h2>
          <Container>
            <CardDeck>
                {post.frontmatter.textcards.map(item => (
                <LandingCardText cardcontent={item.text}
                        heading={item.heading}
                        cardimg={item.image.childImageSharp.fixed}
                 />
                ))}
            </CardDeck>
          </Container>
          </ContentBlock>
        }
        {post.frontmatter.featurecardlist &&        
          <ContentBlock>
            <Container>
                <FeatureCardList cardtitle={post.frontmatter.featurecardlist.cardtitle}
                                 cardsubtitle={post.frontmatter.featurecardlist.cardsubtitle}
                                 carditems={post.frontmatter.featurecardlist.listitems}
                                 cardimage={post.frontmatter.featurecardlist.featurecardimage.childImageSharp.fixed}
                                 imageposition={post.frontmatter.featurecardlist.imageposition}
                                 buttons={post.frontmatter.featurecardlist.buttons}
                                 languageselect={post.frontmatter.featurecardlist.languageselect}
                                 singlebutton={post.frontmatter.featurecardlist.singlebutton}
                                 singlebuttonlink={post.frontmatter.featurecardlist.singlebuttonlink}
                />
            </Container>
          </ContentBlock>
        }
        {post.frontmatter.pagebody &&
          <ContentBlock background={post.frontmatter.pagebodybackground} aligncenter={true}>
            <Container>
              <CenteringDiv>
                <ContentContainer dangerouslySetInnerHTML={{ __html: post.html }}/>
              </CenteringDiv>
            </Container>
          </ContentBlock>
        }
        {post.frontmatter.leadingcalloutbottom  &&
        <Offer offer={post.frontmatter.leadingcalloutbottom}/>
        }
        <PageContact currentlang={post.frontmatter.language}
                     formname={post.frontmatter.title}
                     leadsource={post.fields.slug}
                     pagetype={post.frontmatter.pagetype} 
                     anchor="kursanfrage"
                     anchoren="course-inquiry"
        />
      </SectionMain>
    </Layout>
  )
}

export default LandingPage

export const pageQuery = graphql`
  query LandingPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        title
        imagetitle
        landingpage
        pagetype
        pagebody
        islandingpage
        seo {
          metatitle
          metadescription
          altlanguagelink
          hreflangalt
        }
        supertitle
        displaytitle
        bannerbutton
        language
        heading
        pagecontentblocks {
          heading
          text
        }
        leadingcallout
        leadingcalloutbottom
        offerconditions {
          paragraph
        }
        advantagesheader
        advantages {
          heading
          icon
          text
        }
        cardsheader
        textcards {
          image {
              childImageSharp {
                  fixed(width: 480, height: 320, quality: 80) {
                      ...GatsbyImageSharpFixed_withWebp
                  }
              }
          }
          text
          heading
        }
        featurecardlist {
          cardtitle
          cardsubtitle
          listitems {
            listitem
          }
          buttons {
            button
            buttonlink
          }
          singlebutton
          singlebuttonlink
          imageposition
          languageselect
          featurecardimage {
            childImageSharp {
              fixed(width: 720, height: 720, quality: 80) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
      }
    }
    images:
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        featuredimage{
          childImageSharp {
            fixed(width: 768, height: 512, quality: 80) {
              ...GatsbyImageSharpFixed_withWebp
              srcWebp
            }
          }
        }
      }
    }
    imagexs:
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        featuredimage{
          childImageSharp {
            fixed(width: 480, height: 320, quality: 80) {
              ...GatsbyImageSharpFixed_withWebp
              srcWebp
              src
            }
          }
        }
      }
    }
    imagem:
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        featuredimage{
          childImageSharp {
            fixed(width: 992, height: 661, quality: 80) {
              ...GatsbyImageSharpFixed_withWebp
              srcWebp
            }
          }
        }
      }
    }
    imagel:
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        featuredimage{
          childImageSharp {
            fixed(width: 1200, height: 800, quality: 80) {
              ...GatsbyImageSharpFixed_withWebp
              srcWebp
            }
          }
        }
      }
    }
    imagexl:
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        featuredimage{
          childImageSharp {
            fixed(width: 1920, height: 1280, quality: 80) {
              ...GatsbyImageSharpFixed_withWebp
              srcWebp
            }
          }
        }
      }
    }
    imagesocial:
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        featuredimage{
          childImageSharp {
            fixed(width: 1200, height: 630, quality: 80) {
              ...GatsbyImageSharpFixed_withWebp
              src
              width
              height
            }
          }
        }
      }
    }
  }
  
`