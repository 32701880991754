import React from 'react';
import styled from 'styled-components';
import Container from '../../container'
import {Accordion,AccordionItem,AccordionItemHeading,AccordionItemButton,AccordionItemPanel,} from 'react-accessible-accordion';

const FooterWrapper = styled(Container)`
  border-top: 1px solid #F6F6F6;
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  background-color: #FFFFFF;
  padding: 32px 0;

    @media (min-width:768px) {
      padding: 64px 0;
    }
  @media (min-width:992px) {
    align-items: flex-start;
    flex-direction: row;
    justify-content: center;
  }
`;

const AccordionContainer = styled.div`
  flex-basis: 75%;
  max-width: 75%;
  display: block;
  justify-content: center;
  padding-right: 0.75rem;
padding-left: 0.75rem;
`;

const AccordionButton = styled(AccordionItemButton)`
  padding: 16px 0;
  cursor: pointer;
  width: 100%;
  text-align: center;
  outline: none;
  margin: 0 !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 21px;
  color: rgb(45, 47, 61);
  font-weight: 400;

  @media (min-width:768px) {

  }
  ::after {
    display: inline-block;
    content: '';
    height: 8px;
    width: 8px;
    margin-right: 12px;
    border-bottom: 2px solid currentColor;
    border-right: 2px solid currentColor;
    transform: rotate(45deg);
    color: rgb(29, 29, 29);
    transition: all 200ms cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
  }
  &[aria-expanded='true']::after {
    transform: rotate(-135deg);
    transition: all 200ms cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
  }
`;
const AccordionHeading = styled(AccordionItemHeading)`
  display: block;
  border-bottom: 1px solid #F6F6F6;
  color: rgb(29, 29, 29);
`;

const AccordionItemText = styled(AccordionItemPanel)`
color: rgb(45, 47, 61);
font-size: 18px;
`;

const DesktopFooter = props => (
  <FooterWrapper>
    <AccordionContainer>
          <Accordion allowZeroExpanded={true}>
            <AccordionItem>
                <AccordionHeading>
                  {props.currentlang ==="de" &&
                      <AccordionButton>
                  * Aktionsbedingungen (hier klicken)
                  </AccordionButton>
                  }
                  {props.currentlang ==="en" &&
                      <AccordionButton>
                  *Terms of special offer (click here)
                  </AccordionButton>
                  }
                </AccordionHeading>
                <AccordionItemText>
                {props.offerconditions.map(item => (
                    <p>
                      {item.paragraph}
                    </p>
                ))}
                </AccordionItemText>
            </AccordionItem>
        </Accordion>
    </AccordionContainer>
  </FooterWrapper>
);
export default DesktopFooter;