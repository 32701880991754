import React from 'react'
import Footer from './lp_footer/lp_footer'
import SubFooter from '../../components/footer/subfooter'
import { GlobalStyle } from '../../theme/globalstyle';

const TemplateWrapper = ({ children, offerconditions, currentlang }) => {
  return (
    <div>
      <GlobalStyle />
      {children}
      <Footer langlinkde={"/"} 
              langlinken={"/en/"}
              currentlang={currentlang}
              offerconditions={offerconditions}
      />
      <SubFooter currentlangfooter={currentlang} />
    </div>
  )
}

export default TemplateWrapper
